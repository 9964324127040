import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Gallery = makeShortcode("Gallery");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Gallery mdxType="Gallery">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/b0b5288bc8376ba7b5fd8a77960d4ac5/e2731/01.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "143.00000000000003%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAdABQDASIAAhEBAxEB/8QAGQAAAwEBAQAAAAAAAAAAAAAAAAMEAgUG/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAL/2gAMAwEAAhADEAAAAXT34KRgcmX0mblQwiv/xAAdEAACAgIDAQAAAAAAAAAAAAABAwACETMEEBIT/9oACAEBAAEFAnPvVp5LIsmy2fP2LKErjDtwJi9ff//EABYRAQEBAAAAAAAAAAAAAAAAAAEQEf/aAAgBAwEBPwGAjs//xAAXEQEAAwAAAAAAAAAAAAAAAAAQARES/9oACAECAQE/Aak0f//EAB0QAAIBBAMAAAAAAAAAAAAAAAABAhExQXEQIJH/2gAIAQEABj8CcU0XXhFvI6t10Ze0KlifEddP/8QAHBABAQACAgMAAAAAAAAAAAAAAQARITFREEGh/9oACAEBAAE/IQ1A7IbjPOcoZZ6X9gE3thSbmNX3WZzOiHUh0eP/2gAMAwEAAgADAAAAEPsAwf/EABkRAAIDAQAAAAAAAAAAAAAAAAABEBEhMf/aAAgBAwEBPxB7yNAG7dn/xAAXEQEBAQEAAAAAAAAAAAAAAAAREAEx/9oACAECAQE/EM6QgT//xAAfEAEAAwABBAMAAAAAAAAAAAABABEhMUFRYXGBkeH/2gAIAQEAAT8Qa/ZQK8ER4nUEVcSoFbKTaNAGeSOW1KoUPOEBoLACs9S/A0Cvog6mXvss1vEQ2h+J+IJ6n//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "01",
              "title": "01",
              "src": "/static/b0b5288bc8376ba7b5fd8a77960d4ac5/e5166/01.jpg",
              "srcSet": ["/static/b0b5288bc8376ba7b5fd8a77960d4ac5/f93b5/01.jpg 300w", "/static/b0b5288bc8376ba7b5fd8a77960d4ac5/b4294/01.jpg 600w", "/static/b0b5288bc8376ba7b5fd8a77960d4ac5/e5166/01.jpg 1200w", "/static/b0b5288bc8376ba7b5fd8a77960d4ac5/e2731/01.jpg 1462w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/0a86bafaa0644588cfa63253d488e69e/e2731/02.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "143.00000000000003%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAdABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAQACBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAe2ZBmJQIj//xAAaEAABBQEAAAAAAAAAAAAAAAAQAREgISIx/9oACAEBAAEFAivbDaj/AP/EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABUQAQEAAAAAAAAAAAAAAAAAACAR/9oACAEBAAY/AlV//8QAGBABAQEBAQAAAAAAAAAAAAAAARAAEUH/2gAIAQEAAT8hcxOcl9lcz//aAAwDAQACAAMAAAAQg8YA/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPxAf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPxAf/8QAGxABAQEAAgMAAAAAAAAAAAAAAREAECFRcYH/2gAIAQEAAT8Q4JjoF9TPkX6aZk9cJJnXkd//2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "02",
              "title": "02",
              "src": "/static/0a86bafaa0644588cfa63253d488e69e/e5166/02.jpg",
              "srcSet": ["/static/0a86bafaa0644588cfa63253d488e69e/f93b5/02.jpg 300w", "/static/0a86bafaa0644588cfa63253d488e69e/b4294/02.jpg 600w", "/static/0a86bafaa0644588cfa63253d488e69e/e5166/02.jpg 1200w", "/static/0a86bafaa0644588cfa63253d488e69e/e2731/02.jpg 1462w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/c7f7d7eb84b6d035f0ea89412bb068df/e2731/03.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "143.00000000000003%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAdABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAECBAX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHtyoLGGDN2UQUH/8QAGxAAAgIDAQAAAAAAAAAAAAAAAQIAEBESIzL/2gAIAQEAAQUCsmbCmbsF3i+cTFf/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAeEAABAgcBAAAAAAAAAAAAAAAAARACESAhMTJBkf/aAAgBAQAGPwJ8myetFMtwSj//xAAdEAEAAgICAwAAAAAAAAAAAAABABEQITGRUYGh/9oACAEBAAE/IWWONiQk0h6Q2XNCqBr5B7Lcpo4qI8HUocBj/9oADAMBAAIAAwAAABCjBnH/xAAWEQEBAQAAAAAAAAAAAAAAAAAQESH/2gAIAQMBAT8QLkP/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/EB//xAAcEAEAAwEAAwEAAAAAAAAAAAABABEhMRBBUWH/2gAIAQEAAT8QYFrkOR2VK6grdQqXIR0QiACInSPpAG0OKmX0Po1MY9e6Ka9Rqqz+iakS9oN8f//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "03",
              "title": "03",
              "src": "/static/c7f7d7eb84b6d035f0ea89412bb068df/e5166/03.jpg",
              "srcSet": ["/static/c7f7d7eb84b6d035f0ea89412bb068df/f93b5/03.jpg 300w", "/static/c7f7d7eb84b6d035f0ea89412bb068df/b4294/03.jpg 600w", "/static/c7f7d7eb84b6d035f0ea89412bb068df/e5166/03.jpg 1200w", "/static/c7f7d7eb84b6d035f0ea89412bb068df/e2731/03.jpg 1462w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/b9fd0e787f4f3e3df2828f830918cfb0/e2731/04.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "143.00000000000003%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAdABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAECAwX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHszoiRhaciEH//xAAXEAADAQAAAAAAAAAAAAAAAAAAASEw/9oACAEBAAEFAmXP/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPwEf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAFBABAAAAAAAAAAAAAAAAAAAAMP/aAAgBAQAGPwJP/8QAGBABAAMBAAAAAAAAAAAAAAAAAQAQESD/2gAIAQEAAT8hTs2iEzljX//aAAwDAQACAAMAAAAQw8E8/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPxAf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPxAf/8QAGhABAQEBAAMAAAAAAAAAAAAAAQARMRAh8P/aAAgBAQABPxDXDhP0RqC9lOp7snCyZl8jf//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "04",
              "title": "04",
              "src": "/static/b9fd0e787f4f3e3df2828f830918cfb0/e5166/04.jpg",
              "srcSet": ["/static/b9fd0e787f4f3e3df2828f830918cfb0/f93b5/04.jpg 300w", "/static/b9fd0e787f4f3e3df2828f830918cfb0/b4294/04.jpg 600w", "/static/b9fd0e787f4f3e3df2828f830918cfb0/e5166/04.jpg 1200w", "/static/b9fd0e787f4f3e3df2828f830918cfb0/e2731/04.jpg 1462w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/d5ae053ac743f14ec216240b12f2f717/e2731/05.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "143.00000000000003%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAdABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAECAwX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHuSpKAEtZEIP/EABcQAAMBAAAAAAAAAAAAAAAAAAEQIDH/2gAIAQEAAQUCZZ2f/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPwEf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAFBABAAAAAAAAAAAAAAAAAAAAMP/aAAgBAQAGPwJP/8QAGRAAAwEBAQAAAAAAAAAAAAAAAAERECFR/9oACAEBAAE/IWW4zr6XEEfmMef/2gAMAwEAAgADAAAAEBDJDP/EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8QH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8QH//EABkQAQEBAQEBAAAAAAAAAAAAAAEAESEQUf/aAAgBAQABPxCYHGyMRN49yybWC7P0Q8PRv//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "05",
              "title": "05",
              "src": "/static/d5ae053ac743f14ec216240b12f2f717/e5166/05.jpg",
              "srcSet": ["/static/d5ae053ac743f14ec216240b12f2f717/f93b5/05.jpg 300w", "/static/d5ae053ac743f14ec216240b12f2f717/b4294/05.jpg 600w", "/static/d5ae053ac743f14ec216240b12f2f717/e5166/05.jpg 1200w", "/static/d5ae053ac743f14ec216240b12f2f717/e2731/05.jpg 1462w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/a06a92a7846a40b3e94d1d8d226127ea/e2731/06.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "143.00000000000003%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAdABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAECAwX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHuSoLAHOkiEH//xAAYEAACAwAAAAAAAAAAAAAAAAABEAARIP/aAAgBAQABBQJlkSs//8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPwEf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAFBABAAAAAAAAAAAAAAAAAAAAMP/aAAgBAQAGPwJP/8QAGRAAAwEBAQAAAAAAAAAAAAAAAAEQESEx/9oACAEBAAE/IXWenk6Goxz/2gAMAwEAAgADAAAAEBDBDP/EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8QH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8QH//EABoQAQEBAAMBAAAAAAAAAAAAAAERABAhMUH/2gAIAQEAAT8QxR8bk10vWrUceFyKSd5L6azkd//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "06",
              "title": "06",
              "src": "/static/a06a92a7846a40b3e94d1d8d226127ea/e5166/06.jpg",
              "srcSet": ["/static/a06a92a7846a40b3e94d1d8d226127ea/f93b5/06.jpg 300w", "/static/a06a92a7846a40b3e94d1d8d226127ea/b4294/06.jpg 600w", "/static/a06a92a7846a40b3e94d1d8d226127ea/e5166/06.jpg 1200w", "/static/a06a92a7846a40b3e94d1d8d226127ea/e2731/06.jpg 1462w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
    </Gallery>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      